@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
:root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body[theme="light"]{
  background-color: #fff;
  color: #000;
}

body[theme="dark"]{
  background-color: #121212;
  color:#ffffffde
}

body[theme="dark"] input,
body[theme="dark"] textarea,
body[theme="dark"] select {
  background-color: #1e1e1e; 
  color: #ffffff; 
  border: 1px solid #444; 
  border-radius: 4px; 
  padding: 10px; 
  outline: none; 
}

body[theme="dark"] input::placeholder,
body[theme="dark"] textarea::placeholder {
  color: #aaaaaa; 
}

body[theme="dark"] input:focus,
body[theme="dark"] textarea:focus,
body[theme="dark"] select:focus {
  border-color: #ffffff; 
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5); 
}

body[theme="dark"] .dataBox{
  background-color: #252525;
  color: #ffffffde;
  border: none;
}

body[theme="dark"] .nav{
  background-color: #121212;
  color: #ffffffde;
}

body[theme="dark"] .asideCourse{
  background-color: #121212;
  color: #ffffffde;
}

body[theme="dark"] .menu{
  background-color: #444;
  color: #ffffffde;
}

body[theme="light"] .nav{
  background-color: #fff;
  color: black;
}

body[theme="light"] .asideCourse{
  background-color: #fff;
  color: black;
}
body[theme="light"] .arrow{
  color: black;
}
body[theme="dark"] .arrow{
  color: white;
}
body[theme="light"] .long-line , body[theme="light"] .square{
  background-color: black;
}
body[theme="dark"] .long-line  , body[theme="dark"] .square{
  background-color: white;
}
body[theme="light"] .black-border-box{
  border-color: black;
}
body[theme="dark"] .black-border-box{
  border-color: white;
}



body[theme="dark"] .swiper-btn{
  border: 3px solid white;
  border-radius: 50%;
  padding: 2px 8px;
}
body[theme="light"] .swiper-btn{
  border: 3px solid black;
  border-radius: 50%;
  padding: 2px 8px;
}

#root{
  position: relative;
}

#footerMail{
  color: white; 
  background-color: #181818; 
  outline: none; 
  font-size: 1.125rem; 
  border-radius: 0.375rem; 
  font-weight: bold; 
  padding: 0.5rem 0.5rem; 
  border: none;
}
#footerMail:focus{
  border-color: none; 
  box-shadow: none; 
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="tel"]::-webkit-inner-spin-button,
input[type="tel"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input[type="tel"] {
    -moz-appearance: textfield;
}

input{
  background-color: inherit;
  color: inherit;
}

body::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background: bottom;
  border-radius: 10px;
}
body::-webkit-scrollbar{
  width: 7px;
  background: bottom;
}
body::-webkit-scrollbar-thumb{
  border-radius: 7px;
  background: #dc3545;
}
/* FONTS */
@font-face {
  font-family: 'f-black';
  src: url('../public/fonts/Cairo-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'f-ex-bold';
  src: url('../public/fonts/Cairo-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'f-bold';
  src: url('../public/fonts/Cairo-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'f-semi-bold';
  src: url('../public/fonts/Cairo-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'f-rg';
  src: url('../public/fonts/Cairo-Regular.ttf') format('truetype');
}
/* FONT CLASSES */
.f-black {
  font-family: 'f-black';
}
.f-ex-bold{
  font-family: 'f-ex-bold';
}
.f-bold {
  font-family: 'f-bold';
}
.f-semi-bold {
  font-family: 'f-semi-bold';
}
.f-rg {
  font-family: 'f-rg';
}

a{
  text-decoration: none !important;
  color: inherit !important;
}

/* NAVBAR LINKS */
.link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 3px; 
  bottom: -19px; 
  left: 0;
  background-color: red;
  transition: width 0.3s ease; 
}

.link:hover::after {
  width: 100%; 
}

.active::after {
  content: '';
  position: absolute;
  width: 100%; /* Ensure the active underline is always visible */
  height: 3px; 
  bottom: -19px; 
  left: 0;
  background-color: red;
  transition: width 0.3s ease; /* Keep the transition for consistency */
}
.active{
  opacity: 1;
}
.heroTxt{
  font-size: 4rem;
  position: relative;
  z-index: 1;
  width: fit-content;
}
.heroBg{
  position: absolute;
  top: 2.5rem;
  right: 0;
  z-index: 0;
  width: 100%;
  height: 44%;
  border-radius: 9rem 0rem 1rem 1rem;
}
.red-line{
  margin: 3rem 0;
  background-color: red;
  width: 65%;
  border-radius: 12px;
  height: 2px;
}
.swiper-box-landing{
  display: flex;
  gap: 1.5rem;
}
button{
  border: none;
  background: transparent;
}

/* LANDING SECTION */
.red-bg{
  position: absolute;
  top: 3rem;
  width: fit-content;
  z-index: 10;
  right: 9rem;
  height: 100%;
  width: 25rem;
}
.banner{
  position: absolute;
  top: 6rem;
  width: fit-content;
  z-index: 10;
  right: 19rem;
}
.black-border-box{
  position: absolute;
  top: 2rem;
  right: 4rem;
  height: 70%;
  width: 25rem;
  border-radius: 6rem 6rem 0 0;
  z-index: 15;
}
.curved-line{
  display: flex;
  position: absolute;
  top: 23rem;
  right: 3rem;
  z-index: 14;
}
.x-icon{
  position: absolute;
  top: 9rem;
  right: 3rem;
}
.x-icon-down{
  position: absolute;
  bottom: 16rem;
  right: 14rem;
  z-index: 20;
}
.x-icon-up{
  position: absolute;
  top: 1rem;
  left: 6rem;
  rotate: 270deg;
  width: 1.5rem;
}
.frame{
  position: absolute;
  bottom: 17rem;
  right: 3rem;
}
.our-courses{
  position: absolute;
  left: -4rem;
  top: 12rem;
}
.comunity-box{
  position: absolute;
  background-color: #ffffffde;
  bottom: 11rem;
  left: 6rem;
  display: flex;
  gap: 7px;
  z-index: 25;
  width: fit-content;
  padding: 8px 16px;
  border: 1px solid gray;
  border-radius: 12px;
  -webkit-box-shadow: -17px 15px 3px -8px #00000026;
  -moz-box-shadow: -17px 15px 3px -8px #00000026;
  box-shadow: -17px 15px 3px -8px #00000026;
}

/* USED UNDER HEADER  */

.course-box {
  border: 2px solid black;
  transition: border 0.3s ease, border-radius 0.3s ease;
}

.course-box:hover {
  border: 2px solid red;
  border-radius: 16px;
}
/* WATCH BTN */
.course-box:hover .animate-btn{
  color: white;
}
/* ::before INSIDE .animate-btn  */
.course-box:hover .animate-btn::before {
  top: 0;
}

/* ICON INSIDE .animate-btn */
.course-box:hover .animate-btn i {
  transition: color 0.4s ease;
}

.animate-btn {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'f-rg';
    position: relative;
    display: flex;
    padding: 5px 15px;
    color: red;
    background-color: transparent;
    border: 2px solid #ff5f5f; 
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
    transition: color 0.4s ease, border-color 0.4s ease;
}
/* ICON */
.animate-btn i { 
    color: red; 
    transition: color 0.4s ease;
}

.animate-btn::before {
    content: "";
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: red; /* Background color on hover */
    transition: top 0.4s ease;
    z-index: -1;
}

.animate-btn:hover::before {
    top: 0;
}

.animate-btn:hover {
    color: white; 
    border-color: #ff5f5f;
}

.animate-btn:hover i {
    color: white; 
    transition: color 0.4s ease;
}
.no-ques-answer{
display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'f-rg';
    position: relative;
    display: flex;
    padding: 5px 15px;
    color: red;
    background-color: transparent;
    border: 2px solid #ff5f5f; 
    cursor: not-allowed !important;
    overflow: hidden;
    z-index: 1;

}
.companies{
  display: grid;
  grid-template-columns: repeat(3 , 1fr);
  gap: 10px;
}
.trainers{
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 15px;  
    justify-content: center;
    align-items: center;
    justify-items: center;   
}
.item {
  scroll-snap-align: start;     
  min-width: 200px;               
}
.submit-btn{
background-color: #ff0000;
color: white;
width: 91.666667%; 
margin: 0 0.80rem; 
padding: 0.25rem 1rem;
border-radius: 1.5rem;
box-shadow: 4px 4px 0px 1px rgba(16, 16, 16, 0.81);
}
.mainBg{
  background-image: url('../public/imgs/plansBg.png');
  background-size: cover;
}

.map{
    overflow: hidden;
    padding-bottom: 40.25%;
    position: relative;
    height: 0;
    margin-top: 4em;
}
.map iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
.footerH2::after{
  content: '';
  margin: 10px 0;
  width: calc(100% - 40%);
  height: 2px;
  background-color: red;
  display: block;
  border-radius: 24px;
}
.vidNum::after{
  content: '';
  width: auto;
  height: 2px;
  background-color: red;
  display: block;
  border-radius: 24px;
}
@keyframes darkLoader {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes lightLoader {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.darkLoading {
  display: inline-block;
  background: #444;
  background-image: linear-gradient(to right, #444 0%, #555 20%, #444 40%, #666 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  animation: darkLoader 2s infinite linear;
}

.lightLoading {
  display: inline-block;
  background: #eee;
  background-image: linear-gradient(to right, #eee 0%, #ddd 20%, #eee 40%, #ccc 100%);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  animation: lightLoader 2s infinite linear;
}

@media (max-width: 1000px) {
  .main-hero{
    width: 100%;
  }
  .red-line{
    width: 80%;
  }
  .swiper-box{
    width: 90%;
  }
}
@media (max-width: 675px) {
  .swiper-line{
    display: none;
  }
  .swiper-btn{
    display: none;
  }
  .swiper-box-landing{
    display: grid;
  }
  .hide-scrollbar{   
  scrollbar-width: none;  
  -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;  /* For Chrome, Safari, and Opera */
}
}
