.landing{
    height: calc(100vh - 100px);
}
.swiper-box-landing .img-box a{
height: 200px;
width: 200px;
}
.swiper-box-landing .img-box a img{
height: 100%;
width: 100%;
}
@media (max-width:767px) {
    .landing{height: auto;}
    .swiper-box-landing .img-box a{
        height: 200px;
        width: auto;
        }
}